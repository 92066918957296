import React, { Suspense } from "react";
import AuthenticatedGuard from "../guards/authenticatedGuard";
import { Routes, Route, Outlet } from "react-router-dom";
import Loading from "../components/loading";
import Pager from "../pages/Home";
import routeList from "./routesList";
import { Container } from "@mantine/core";
export default function home() {
  return (
    <Container 
      fluid={true}
      px={0} 
      sx={{ height: "100vh", width:"100%" }}
    >
      <Routes>
        {routeList.map((route) => (
          <Route
            exact
            path={route.path}
            key="parent"
            element={route.isProtected ? <AuthenticatedGuard /> : <Outlet />}
          >
            <Route
              exact
              key="child"
              path={route.path}
              element={
                <>
                  <Suspense fallback={<Loading />}>
                    <Pager page={route.page} />
                  </Suspense>
                </>
              }
            />
          </Route>
        ))}
      </Routes>
    </Container>
  );
}
