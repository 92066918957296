import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));
const defaultTheme = createTheme(); // or your custom theme

root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        colors: {
          brandPrimary: "#50cdb5",
          brandSecondary: "#023194",
        },
      }}
    >
      <ModalsProvider>
        <ThemeProvider theme={defaultTheme}>
          <Notifications />
          <App />
          <ToastContainer />
        </ThemeProvider>
        <ToastContainer />
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);
