import { PATH } from "../constants/paths";
import { lazy } from "react";

const Splash = lazy(() => import("../pages/Main"));

const routes = [
  {
    path: PATH.ROOT,
    key: PATH.ROOT,
    isProtected: false,
    page: <Splash />,
  },
];
export default routes;
