import React from "react";
import { Container } from "@mantine/core";

export default function home({ page }) {
  return (
    <Container fluid m={0} p={0}>
      {page}
    </Container>
  );
}
